import { AvailCompType, AvailData, AvailItem, AvailRow, AvailService } from "../../services/avail.service";
import { S25Util } from "../../util/s25-util";
import { AriaLive } from "../../services/aria.live.service";
import {
    AvailGridPerms,
    AvailMoveItem,
    AvailPen,
    AvailResizeItem,
    OfficeHours,
} from "./s25.availability.grid.component";
import { Proto } from "../../pojo/Proto";
import { Flavor } from "../../pojo/Util";
import { EventData, EventService } from "../../services/event.service";
import { Item } from "../../pojo/Item";
import { S25Const } from "../../util/s25-const";
import { OptUtilizationView } from "../s25-opt/s25.opt.component";
import { ColorBucketType } from "../../services/item.color.mapping.service";
import ISODateString = Proto.ISODateString;
import Ids = Item.Ids;
import DowNumber = Proto.DowNumber;
import { AvailWeekly } from "../s25-avail-weekly/s25.avail.weekly.util";

export const AvailUtil = {
    addItem(row: AvailRow, item: AvailItem) {
        const newItems = S25Util.array.flatten(row.tracks).concat(item);
        row.tracks = AvailService.getTracksOverlayConflicts(newItems);
    },

    // Unlike "addItem" this method does not reflow all tracks and simply finds the next open space
    addItemStatic(row: AvailRow, item: AvailItem) {
        let track = row.tracks.find((track) => {
            const blocked = track.find((trackItem) => trackItem.end > item.start && trackItem.start < item.end);
            return !blocked;
        });
        if (!track) {
            track = [];
            row.tracks.push(track);
        }
        track.push(item);
        track.sort((a, b) => a.start - b.start);
    },

    copyItem(item: AvailItem) {
        const copy = S25Util.deepCopy(item, { row: true }) as AvailItem;
        copy.gridData.row = item.gridData.row;
        copy.gridData.isCopy = true;
        AvailUtil.addItemStatic(item.gridData.row, copy);
    },

    removeItem(item: AvailItem) {
        const items = S25Util.array.flatten(item.gridData.row.tracks).filter((a: AvailItem) => a !== item);
        item.gridData.row.tracks = AvailService.getTracksOverlayConflicts(items);
    },

    relocateItem(data: { item: AvailItem; start: number; end: number; destination: AvailRow }) {
        const { item, start, end, destination } = data;
        // Remove item from previous location
        AvailUtil.removeItem(item);

        // Update item
        item.eventStartTime += start - item.start;
        item.eventEndTime += end - item.end;
        item.start = start;
        item.end = end;
        item.eventStart = ((item.eventStartTime - start) / (end - start)) * 100;
        item.eventEnd = ((end - item.eventEndTime) / (end - start)) * 100;

        // Add item to new track
        AvailUtil.addItem(destination, item);
    },

    startMovingItem(event: Event, item: AvailItem, is24Hours: boolean) {
        AriaLive.announce("Picked up event", true);
        const element = (event.target as HTMLElement).closest(".item") as HTMLElement;
        const trackElement = element.closest(".track") as HTMLElement;
        const gridElement = trackElement.closest(".body") as HTMLElement;
        item.gridData.timeLabels = AvailUtil.getEventTimeLabels(item, is24Hours);
        return {
            init: { itemLeft: element.offsetLeft, itemTop: element.offsetTop },
            maxOffset: {
                left: -element.offsetLeft,
                right: trackElement.offsetWidth - element.offsetLeft - element.offsetWidth,
                top: -trackElement.offsetTop,
                bottom: gridElement.offsetHeight - trackElement.offsetTop - trackElement.offsetHeight,
            },
            item,
        };
    },

    updateMoveItem(item: AvailItem, hoursMoved: number, snapToGridUnit: number) {
        const { start, end, eventStartTime, eventEndTime } = item;
        const snapMultiplier = 60 / snapToGridUnit;
        const newEventStartTime = Math.round((eventStartTime + hoursMoved) * snapMultiplier) / snapMultiplier; // Align to closest snapToGridUnit
        hoursMoved = newEventStartTime - eventStartTime; // Update hours moved to reflect alignment

        return {
            start: start + hoursMoved,
            end: end + hoursMoved,
            eventStartTime: newEventStartTime,
            eventEndTime: eventEndTime + hoursMoved,
        };
    },

    startResizingItem(event: Event, item: AvailItem, side: "left" | "right", officeHours: OfficeHours) {
        AriaLive.announce(`Editing ${side === "left" ? "start" : "end"} time`, true);
        const target = event.target as HTMLElement;
        const textElement = target.parentElement.querySelector(".itemText") as HTMLElement;
        const itemElement = target.closest(".item") as HTMLElement;
        const setupElement = target.parentElement.querySelector(".colorOverlay") as HTMLElement;
        const takedownElement = target.parentElement.querySelector(".itemText ~ .colorOverlay") as HTMLElement;
        const min = side === "left" ? 0 : item.eventStartTime + 5 / 60 - (item.eventEndTime - item.end);
        const max = side === "right" ? 24 : item.eventEndTime - 5 / 60 - (item.eventStartTime - item.start);
        if (setupElement) item.gridData.style.setupWidth = `${setupElement.offsetWidth}px`; // Keep setup width static
        if (takedownElement) item.gridData.style.takedownWidth = `${takedownElement.offsetWidth}px`; // Keep takedown width static
        return {
            side,
            init: {
                itemLeft: itemElement.offsetLeft,
                itemWidth: itemElement.offsetWidth,
                eventWidth: textElement.offsetWidth,
            },
            item,
            min: Math.max(officeHours.start, min),
            max: Math.min(officeHours.end, max),
            handle: event.target as HTMLElement,
        };
    },

    adjustResizeItem(a: number, b: number, hoursMoved: number, min: number, max: number, snapMultiplier: number) {
        let b2 = Math.round((b + hoursMoved) * snapMultiplier) / snapMultiplier; // Align to the closest allowed interval
        const a2 = S25Util.clamp(a + b2 - b, min, max); // Make sure we're between min and max
        return a2 - a; // Return change in hours
    },

    updateResizeMinMax(item: AvailResizeItem, time: number, newTime: number, snapToGridUnit: number) {
        if (newTime < time) item.min = Math.max(item.min, newTime + snapToGridUnit / 60);
        if (newTime > time) item.max = Math.min(item.max, newTime - snapToGridUnit / 60);
    },

    penStartCreate(pen: AvailPen, date: ISODateString, init: number, officeHours: OfficeHours, is24Hours: boolean) {
        pen.create = {
            date,
            fixedTime: pen.hours,
            floatTime: pen.hours + 0.5,
            init,
            min: officeHours.start,
            max: officeHours.end,
        };
        pen.timeLabels = {
            start: S25Util.date.toTimeStrFromHours(pen.hours, is24Hours),
            end: S25Util.date.toTimeStrFromHours(pen.hours + 0.5, is24Hours),
        };
    },

    snapPenCreate(pen: AvailPen, offset: number, floatTime: number, snapToGridUnit: number) {
        const { fixedTime, min, max } = pen.create;

        const snapMultiplier = 60 / snapToGridUnit;
        floatTime = Math.round(floatTime * snapMultiplier) / snapMultiplier; // Snap!
        if (offset >= 0) floatTime = S25Util.clamp(floatTime, fixedTime + snapToGridUnit / 60, max);
        if (offset <= 0) floatTime = S25Util.clamp(floatTime, min, fixedTime - snapToGridUnit / 60);
        return floatTime;
    },

    penUpdateTimeLabels(pen: AvailPen, start: number, end: number, is24Hours: boolean) {
        pen.timeLabels = {
            start: S25Util.date.toTimeStrFromHours(start, is24Hours),
            end: S25Util.date.toTimeStrFromHours(end, is24Hours),
        };
    },

    penCreateEvent(pen: AvailPen, itemType: Item.Id, itemId: number) {
        const { date, fixedTime, floatTime } = pen.create;
        const [start, end] = [fixedTime, floatTime].sort((a, b) => a - b);
        return AvailService.createEvent(
            {
                itemId,
                startDt: date,
                startTime: S25Util.date.toTimeStrFromHours(start, true),
                endDt: date,
                endTime: S25Util.date.toTimeStrFromHours(end, true),
            },
            S25Const.itemId2Name[itemType],
        );
    },

    getOffsetFromMouse(event: MouseEvent, moveItem: AvailMoveItem) {
        const { init, item, maxOffset } = moveItem;
        return {
            x: S25Util.clamp(event.pageX - init.x, maxOffset.left, maxOffset.right),
            y: S25Util.clamp(event.pageY - init.y, maxOffset.top, maxOffset.bottom),
        };
    },

    getTrackFromMouseOffset(event: MouseEvent, moveItem: AvailMoveItem, rows: AvailRow[]) {
        if (event.pageY - moveItem.init.y <= moveItem.maxOffset.top) return rows[0];
        if (event.pageY - moveItem.init.y >= moveItem.maxOffset.bottom) return rows[rows.length - 1];
        const text = document
            .elementFromPoint(document.body.offsetWidth / 2, event.clientY)
            .closest(".availRow")
            .getAttribute("data-text");
        return rows.find((row) => row.text === text);
    },

    getEventTimeLabels(
        item: { start: number; end: number; eventStartTime?: number; eventEndTime?: number },
        is24Hours: boolean,
    ) {
        return {
            pre: S25Util.date.toTimeStrFromHours(item.start, is24Hours),
            start: S25Util.date.toTimeStrFromHours(item.eventStartTime, is24Hours),
            end: S25Util.date.toTimeStrFromHours(item.eventEndTime, is24Hours),
            post: S25Util.date.toTimeStrFromHours(item.end, is24Hours),
        };
    },

    getAriaTime(hours: number, is24Hours: boolean) {
        return S25Util.date.ariaTimeString(S25Util.date.toTimeStrFromHours(hours, is24Hours));
    },

    pixelsToHours(trackWidth: number, px: number, officeHours: OfficeHours) {
        const visibleHours = officeHours.end - officeHours.start;
        return (px / trackWidth) * visibleHours;
    },

    hoursToPixels(trackWidth: number, hours: number, officeHours: OfficeHours) {
        const visibleHours = officeHours.end - officeHours.start;
        return (hours / visibleHours) * trackWidth;
    },

    getPermAtTimeLocRes(
        perms: AvailGridPerms,
        userTimezone: Flavor<string, "IANATimezone">,
        instanceTimezone: Flavor<string, "IANATimezone">,
        date: ISODateString,
        startHour: number,
        endHour: number,
        locations: number[],
        resources: number[],
    ) {
        const permArray = [];
        for (let id of locations || []) permArray.push(perms.location.get(id));
        for (let id of resources || []) permArray.push(perms.resource.get(id));

        const start = S25Util.date.addMinutes(date, Math.round(startHour * 60));
        const end = S25Util.date.addMinutes(date, Math.round(endHour * 60));
        const finalPerms = permArray.map((perm) => {
            if (perm) {
                if (perm.assignOverride) {
                    return "requestAssign";
                }
                return AvailService.coalescePerms(
                    perm.assignPerm,
                    perm.exceptionDateList,
                    perm.exceptionDays,
                    perm.dateBuffer,
                    start,
                    end,
                    { instance: instanceTimezone, user: userTimezone },
                );
            } else {
                return "notSet";
            }
        });
        return AvailService.lowestPerm(finalPerms);
    },

    getPermAtTime(
        perms: AvailGridPerms,
        userTimezone: Flavor<string, "IANATimezone">,
        instanceTimezone: Flavor<string, "IANATimezone">,
        view: AvailCompType,
        itemType: Item.Id,
        itemId: number,
        row: AvailRow,
        startHour: number,
        endHour: number,
        eventData?: EventData,
        reservationId?: number,
    ) {
        const startArgs = [perms, userTimezone, instanceTimezone, row.date, startHour, endHour] as const;
        if (view === "availability_daily" && itemType === Ids.Location)
            return AvailUtil.getPermAtTimeLocRes(...startArgs, [itemId], []);
        if (view === "availability_daily" && itemType === Ids.Resource)
            return AvailUtil.getPermAtTimeLocRes(...startArgs, [], [itemId]);
        if (view === "availability_schedule") {
            const reservation = EventService.extractReservations(eventData).find(
                (res) => res.reservation_id === reservationId,
            );
            const locations = reservation.space_reservation?.map((res) => res.space_id) || [];
            const resources = reservation.resource_reservation?.map((res) => res.resource_id) || [];
            return AvailUtil.getPermAtTimeLocRes(...startArgs, locations, resources);
        }
        if (view === "availability_home" || view === "availability") {
            const locations = row.itemType !== 4 ? [] : [row.itemId];
            const resources = row.itemType !== 6 ? [] : [row.itemId];
            return AvailUtil.getPermAtTimeLocRes(...startArgs, locations, resources);
        }
    },

    getEventPositioning(item: AvailItem, officeHours: OfficeHours): Partial<AvailItem["gridData"]["style"]> {
        const fakeEnd = officeHours.start - item.start + item.end;
        return {
            setupWidth: item.eventStart + "%",
            takedownWidth: item.eventEnd + "%",
            itemLeft: `${AvailUtil.getDayFraction(item.start, officeHours)}%`,
            itemWidth: `${AvailUtil.getDayFraction(fakeEnd, officeHours)}%`,
            eventWidth: `${100 - item.eventStart - item.eventEnd}%`,
            itemTop: undefined,
        };
    },

    getEventColoring(item: AvailItem, utilizationView: OptUtilizationView, colorMap: ColorBucketType) {
        if (utilizationView === "none") {
            for (let bucket of colorMap?.buckets || []) {
                const { hash, pattern } = bucket.color;

                const styling = (pattern?: string) => ({
                    backgroundColor: bucket.color.hash,
                    color: bucket.color.textColor,
                    pattern,
                    colorName: `${bucket.bucket_name} ${bucket.color.name}`,
                });

                if (bucket.type === "Event Type") {
                    if (item.eventType === undefined) continue;
                    if (!bucket.items?.find((bucketItem) => bucketItem.itemId === item.eventType)) continue;

                    if (item.type === 1) {
                        return styling(pattern?.css.replace(/;$/, "") || "none");
                    } else if (item.type === 7 && hash) {
                        const pattern = `linear-gradient(45deg,${hash} 25%,#c4c6c0 25%,#c4c6c0 50%,${hash} 50%,${hash} 75%,#c4c6c0 75%);`;
                        return styling(pattern);
                    } else if (item.type === 8 && hash) return styling();
                } else if (bucket.type === "State") {
                    if (item.eventState === undefined) continue;
                    if (!bucket.items.find((bucketItem) => bucketItem.itemId === item.eventState)) continue;
                    if (item.type !== 1) continue;
                    return styling(pattern?.css.replace(/;$/, "") || "none");
                }
            }
            return { backgroundColor: undefined, color: undefined, pattern: undefined };
        }
        const utilization = item.utilization[utilizationView];
        if (utilization === undefined) return {};
        const bg = utilization > 100 ? "#FF0000" : S25Util.interpolateColor(utilization / 100, "#FFFFFF", "#0000FF");
        const isBright = utilization >= 0 && S25Util.colorBrightness(bg.replace(/^#/, "")) / 255 > 0.45;

        return {
            backgroundColor: bg,
            color: isBright ? "black" : "white",
            pattern: item.gridData?.style.pattern,
        };
    },

    getDayFraction(hours: number, officeHours: OfficeHours): number {
        const passed = hours - officeHours.start;
        const interval = officeHours.end - officeHours.start;
        return (100 * passed) / interval; // PERCENT
    },

    getItemGridData(
        row: AvailRow,
        item: AvailItem,
        utilizationView: OptUtilizationView,
        colorMap: ColorBucketType,
        officeHours: OfficeHours,
        is24Hours: boolean,
    ) {
        return {
            ...(item.gridData || {}),
            row,
            itemId: row.itemId,
            style: {
                ...(item.gridData?.style || {}),
                ...AvailUtil.getEventPositioning(item, officeHours),
                ...AvailUtil.getEventColoring(item, utilizationView, colorMap),
            } as AvailItem["gridData"]["style"],
            timeLabels: AvailUtil.getEventTimeLabels(item, is24Hours),
        } as AvailItem["gridData"];
    },

    getReservationTypeLabel(item: AvailItem) {
        switch (item.type) {
            case AvailWeekly.rsrvType.Blackout:
            case AvailWeekly.rsrvType.Closed:
            case AvailWeekly.rsrvType.Pending:
            case AvailWeekly.rsrvType.Related:
            case AvailWeekly.rsrvType.Requested:
            case AvailWeekly.rsrvType.Draft:
                return AvailWeekly.rsrvTypeLabel[item.type];
            default:
                return item.gridData.style.colorName || "Default Event Green";
        }
    },

    getItemAriaLabel(item: AvailItem, is24Hours: boolean) {
        const date = new Date(item.gridData.row.date).toString().slice(0, 15);
        const status = AvailUtil.getReservationTypeLabel(item);
        const ariaStart = AvailUtil.getAriaTime(item.start, is24Hours);
        const ariaEnd = AvailUtil.getAriaTime(item.end, is24Hours);
        if (item.type === 2 || item.type === 3)
            return `${date} ${item.text} from ${ariaStart} until ${ariaEnd}, ${status}`;
        const actionHint = !item.gridData.canEdit ? "" : ", Editable, Press enter to pick up";
        return `${item.text}, ${date} from ${ariaStart} until ${ariaEnd}, ${status}${actionHint}`;
    },

    updateReservation(data: {
        view: AvailCompType;
        start: number;
        end: number;
        updateAllOccs?: 0 | 1 | 2;
        updateResources?: boolean;
        newDate: ISODateString;
        eventId: number;
        reservationId?: number;
        newLocationId?: number;
        oldLocationId?: number;
        newResourceId?: number;
        oldResourceId?: number;
    }) {
        const { view, start, end, updateAllOccs, updateResources } = data;
        return S25Util.Maybe(
            AvailService.updateRsrvCheckConflict({
                event_id: data.eventId,
                reservation_id: data.reservationId,
                start_dt: S25Util.date.toS25ISODateTimeStr(
                    S25Util.date.addMinutes(data.newDate, Math.round(start * 60)),
                ),
                end_dt: S25Util.date.toS25ISODateTimeStr(S25Util.date.addMinutes(data.newDate, Math.round(end * 60))),
                space_id: view === "availability_schedule" ? undefined : data.newLocationId,
                space_id_orig: view === "availability_schedule" ? undefined : data.oldLocationId,
                resource_id: view === "availability_schedule" ? undefined : data.newResourceId,
                resource_id_orig: view === "availability_schedule" ? undefined : data.oldResourceId,
                update_all_occ: updateAllOccs,
                update_res: updateResources,
            }),
        );
    },

    copyReservation(data: {
        start: number;
        end: number;
        date: ISODateString;
        newLocationId?: number;
        oldLocationId?: number;
        newResourceId?: number;
        oldResourceId?: number;
        reservationId: number;
        eventId: number;
    }) {
        const { start, end, date, newLocationId, oldLocationId, newResourceId, oldResourceId, reservationId, eventId } =
            data;
        return S25Util.Maybe(
            AvailService.copyReservation(
                newLocationId,
                oldLocationId,
                reservationId,
                S25Util.date.toS25ISODateTimeStr(S25Util.date.addMinutes(date, Math.round(start * 60))),
                S25Util.date.toS25ISODateTimeStr(S25Util.date.addMinutes(date, Math.round(end * 60))),
                eventId,
                newResourceId,
                oldResourceId,
            ),
        );
    },

    extractOfficeHours(data: AvailData): OfficeHours {
        return {
            start: data.headers[0].time,
            end: data.headers[data.headers.length - 1].time + 1,
        };
    },

    filterDaysOfWeek(data: AvailData, visibleDays: Set<DowNumber>) {
        data.rows = data.rows.filter((row) => {
            const day = S25Util.date.parseDropTZ(row.date).getDay() as DowNumber;
            return visibleDays.has(day);
        });
        return data;
    },

    findPreviousTrack(track: HTMLElement): HTMLElement {
        // Check within row
        if (track.previousElementSibling?.classList.contains("track"))
            return track.previousElementSibling as HTMLElement;
        // Check previous row
        const newTrack = track.closest(".availRow").previousElementSibling?.querySelector(".track:last-child");
        return (newTrack as HTMLElement) || track; // If no previous track, return original track
    },

    findNextTrack(track: HTMLElement): HTMLElement {
        // Check within row
        if (track.nextElementSibling?.classList.contains("track")) return track.nextElementSibling as HTMLElement;
        // Check next row
        const newTrack = track.closest(".availRow").nextElementSibling?.querySelector(".track");
        return (newTrack as HTMLElement) || track; // If no next track, return original track
    },
};
